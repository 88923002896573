import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Wrapper from '../components/wrapper'
import Article from '../components/article';

const IndexPage = () => (
    <Layout title="Privacy policy">
        <SEO title="Privacy policy" />
        <Wrapper>
            <Article>
                <h1>Privacy Policy</h1>

                <p>Effective date: January 09, 2024</p>

                <p>ThreadHeap ("us", "we", or "our") operates the threadheap.com website (the "Service").</p>

                <p>We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of
                    information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy
Policy have the same meanings as in our Terms and Conditions, accessible from threadheap.com</p>

                <h2>Information Collection And Use</h2>

                <p>
                We may collect, hold, use and disclose personal information for the following purposes:

                * to enable you to access and use our services or the third party services, including storing it;
                * to contact and communicate with you;
                * for internal record keeping and administrative purposes;
                * to comply with our legal obligations and resolve any disputes that we may have.
                </p>

                <h2>
                Disclosure of personal information to third parties
                </h2>

                <p>
                We may disclose personal information to:

                * third party service providers for the purpose of enabling them to provide their services, including (without limitation) IT service providers, data storage, web-hosting and server providers, maintenance or problem-solving providers, marketing providers, professional advisors and payment systems operators;
                * courts, tribunals, regulatory authorities and law enforcement officers, as required by law, in connection with any actual or prospective legal proceedings, or in order to establish, exercise or defend our legal rights;
                * for internal record keeping and administrative purposes;
                * third parties to collect and process data, such as Google Analytics or other relevant businesses. This may include parties that store data outside of Australia including in the U.S.A.

                Where we disclose your personal information to third parties, including data processors, we will request or ensure that the third party handle your personal information in accordance with this Privacy Policy. The third party will only process your personal information in accordance with written instructions from us and we require that the third party either complies with the privacy shield principles set out in the GDPR or another mechanism set out by applicable EU & Swiss data protection laws for the transfer and processing of personal information. When we refer to ‘processing’ in this clause and this Privacy Policy in general, we mean any operation or set of operations which is performed on personal information, whether or not by automated means, such as collecting, recording, organising, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available personal information.

                Please note that we use the following third parties to process your personal information: Google API Services, Google Sign-In, Google Analytic, Stripe.

                By providing us with personal information, you consent to the disclosure of your personal information to third parties who are outside Australia and, if you are a European Union (EU) citizen, to third parties that are outside the EU. Where the disclosure of your personal information is solely subject to Australian privacy laws (and not subject to the GDPR), you acknowledge that we are not required to ensure that those third parties comply with Australian privacy laws.
                </p>

                <h2>
                Your rights and controlling your personal information
                </h2>

                <p>
                If we receive personal information about you from a third party, we will protect it as set out in this Privacy Policy. If you are a third party providing personal information about somebody else, you represent and warrant that you have such person’s consent to provide the personal information to us.

                If you believe that we have breached the Australian Privacy Principles or an article of the GDPR and wish to make a complaint, please contact us using the details below and provide us with full details of the alleged breach. We will promptly investigate your complaint and respond to you, in writing, setting out the outcome of our investigation and the steps we will take to deal with your complaint. You also have the right to contact the Office of the Australian Information Commissioner if you wish to make a complaint.
                </p>

                <h2>
                Security
                </h2>

                <p>
                We have strict security measures in place to try to protect your information and make sure that it is not lost, damaged or accessed by anyone who should not see it.
                Additionally, we conduct regular security audits on these services and access to this data is restricted to only authorized employees of ThreadHeap with suitable training and background checks.In the unlikely event of an unauthorized disclosure of your data we will notify you by email and, when possible, by telephone, and act in accordance with our legal requirements to resolve such disclosure and prevent any future unauthorized disclosure. Every user affected by the unauthorized disclosure will be notified us.
                </p>

                <h2>
                Usage Data
                </h2>

                <p>
                We also automatically collect information how the Service is accessed and used. This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
                </p>

                <h2>
                Use of Data
                </h2>

                <p>
                ThreadHeap uses the collected data for various purposes:

                * To provide and maintain our Service

                * To notify you about changes to our Service

                * To allow you to participate in interactive features of our Service when you choose to do so

                *To provide customer support

                *To gather analysis or valuable information so that we can improve our website and Service

                * To monitor the usage of our Service

                * To detect, prevent and address technical issues

                * To provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information

                *To assist us in advertising on unaffiliated websites and in evaluating the success of our adverting campaigns (including our online targeted advertising and offline promotional campaigns)

                *To administer surveys and questionnaires, such as for market research or customer satisfaction purposes

                *To comply with legal obligations, as part of our general business operations, and for other business administration purposes where we believe necessary to investigate, prevent or take action regarding illegal activities, suspected fraud, situations involving potential threats to the safety of any person, or violations of our Terms of Use or this Privacy Policy.
                </p>

                <h2>
                Data retention
                </h2>

                <p>
                ThreadHeap will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies. ThreadHeap will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.
                </p>

                <h3>
                European Union Residents
                </h3>

                <p>
                If you are a resident of the European Union, you have certain additional rights with respect to your personal information under the General Data Protection Regulation (Regulation (EU) 2016/679) (the “GDPR”), including the following:

                * The right of access to your personal information.
                * The right to rectify your personal information if it is incorrect or incomplete.
                * The right to have your personal information erased (“right to be forgotten”) if certain grounds are met.
                * The right to withdraw your consent to our processing of your personal information at any time (if our processing is based on consent).
                * The right to object to our processing of your personal information (if processing is based on legitimate interests).
                * The right to object to our processing of your personal information for direct marketing purposes.
                * The right to receive your personal information from us in a structured, commonly used and machine-readable format, and the right to transmit your personal information to another controller without hindrance from us (data portability).

                If you are located in the European Union and you are or have been a ThreadHeap customer, we may send you marketing communications based on our legitimate interests, subject always to your right to opt out of such communications. You may contact us at privacy@threadheap.com to exercise any of the above rights. We may request specific information from you to confirm your identity, and in some circumstances we may charge a reasonable fee for access to your personal information. For purposes of the GDPR, we are a “controller” and you are a “data subject.”

                Pursuant to the Privacy Shield Frameworks, EU, UK and Swiss individuals have the right to obtain our confirmation of whether we maintain Personal Information relating to you in the United States. Upon request, we will provide you with access to the Personal Information that we hold about you. You may also correct, amend, or delete the Personal Information we hold about you. An individual who seeks access, or who seeks to correct, amend, or delete inaccurate data transferred to the United States under Privacy Shield, should direct their query to privacy@threadheap.com. If requested to remove data, we will respond within a reasonable timeframe.
                </p>

                <h2>
                Data removal policy
                </h2>

                <p>
                In compliance with the Privacy Shield Principles, ThreadHeap commits to resolve complaints about your privacy and our collection or use of your Personal Information transferred to the United States pursuant to Privacy Shield. European Union and Swiss individuals with Privacy Shield inquiries or complaints should first contact ThreadHeap by email at privacy@threadheap.com.
                </p>

                <h2>
                Links To Other Sites
                </h2>

                <p>
                Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We recommend you review the Privacy Policy of every site you visit. We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.
                </p>

                <h2>
                Service Providers
                </h2>

                <p>
                We may employ third party companies and individuals to perform functions on our behalf, including to facilitate our Service ("Service Providers"), to provide the Service on our behalf, to perform Service-related services or to assist us in analyzing how our Service is used. These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.
                </p>

                <h2>
                Cookies and web beacons
                </h2>

                <p>
                We may use cookies on our Site from time to time. Cookies are text files placed in your computer's browser to store your preferences. Cookies, by themselves, do not tell us your email address or other personally identifiable information. However, they do allow third parties, such as Google and Facebook, to cause our advertisements to appear on your social media and online media feeds as part of our retargeting campaigns. If and when you choose to provide our Site with personal information, this information may be linked to the data stored in the cookie.

                We may use web beacons on our Site from time to time. Web beacons (also known as Clear GIFs) are small pieces of code placed on a web page to monitor the visitor’s behaviour and collect data about the visitor’s viewing of a web page. For example, web beacons can be used to count the users who visit a web page or to deliver a cookie to the browser of a visitor viewing that page. We may use Google Analytics to collect and process data. To find out how Google uses data when you use third party websites or applications, please see  or any other URL Google may use from time to time.
                </p>

                <h2>
                Amendments
                </h2>

                <p>
                We may, at any time and at our discretion, vary this Privacy Policy. We will notify you if we amend this Privacy Policy, by contacting you through the contact details you have provided to us. Any amended Privacy Policy is effective once we notify you of the change.

                For any questions or notices, please contact our Privacy Officer at

                By email: privacy@threadheap.com
                </p>
            </Article>
        </Wrapper>
    </Layout>
);

export default IndexPage
